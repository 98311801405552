/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export function AsideMenuMain() {
  const intl = useIntl();
  const [isAdmin, setIsAdmin] = useState(false);

  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      const metadata =
        user["https://reviews-api.konfidency.com.br/app_metadata"];
      if (metadata.customer === "konfidency") {
        setIsAdmin(true);
      }
    }
  }, []);

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotone/Design/PenAndRuller.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">Konfidency Reviews</span>
        </div>
      </div>
      <AsideMenuItemWithSub to="/reviews" title="Avaliações de produtos" fontIcon="bi-archive" icon="/media/icons/duotone/General/Star.svg">
        <AsideMenuItem to="/reviews/moderate" title="Moderação" hasBullet={true} />
        <AsideMenuItem to="/reviews/all" title="Todas as avaliações" hasBullet={true} />
        <AsideMenuItem to="/reviews/removed" title="Avaliações reprovadas" hasBullet={true} />
        <AsideMenuItem to="/reviews/merchant-center" title="Google Merchant Center" hasBullet={true} />
        <AsideMenuItem to="/reviews/exports" title="Relatórios exportados" hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to="/store-reviews" title="Avaliações de loja" fontIcon="bi-archive" icon="/media/icons/duotone/Home/Building.svg">
        <AsideMenuItem to="/store-reviews/moderate" title="Moderação" hasBullet={true} />
        <AsideMenuItem to="/store-reviews/all" title="Todas as avaliações" hasBullet={true} />
        <AsideMenuItem to="/store-reviews/removed" title="Avaliações reprovadas" hasBullet={true} />
        <AsideMenuItem to="/store-reviews/exports" title="Relatórios exportados" hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/questions"
        title="Perguntas &amp; Respostas"
        fontIcon="bi-archive"
        icon="/media/icons/duotone/Code/Question-circle.svg"
      >
        <AsideMenuItem to="/questions/unanswered" title="Não respondidas" hasBullet={true} />
        <AsideMenuItem to="/questions/all" title="Todas as perguntas" hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to="/products" title="Produtos" fontIcon="bi-archive" icon="/media/icons/duotone/Shopping/Barcode.svg">
        <AsideMenuItem to="/products/all" title="Todos os produtos" hasBullet={true} />
        <AsideMenuItem to="/products/report" title="Relatório" hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/notifications"
        title="Notificações"
        icon="/media/icons/duotone/Communication/Chat5.svg"
        fontIcon="bi-bell-fill"
      >
        <AsideMenuItem
          to="/notifications/sequences"
          title="Gerenciar cadências"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/notifications/trigger"
          title="Enviar manualmente"
          hasBullet={true}
        />
        <AsideMenuItem to="/notifications/templates" title="Templates de e-mail" hasBullet={true} />
        <AsideMenuItem
          to="/notifications/test"
          title="Enviar teste"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/notifications/bulk-send"
          title="Enviar base histórica"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/notifications/whatsapp-settings"
          title="Definições WhatsApp"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to="/crafted/accounts" title="Configurações" icon="/media/icons/duotone/Shopping/Settings.svg" fontIcon="bi-person">
        <AsideMenuItem to="/review-settings/store" title="Configurações da loja" hasBullet={true} />
        <AsideMenuItem to="/review-settings/attributes" title="Atributos de avaliações" hasBullet={true} />
        <AsideMenuItemWithSub to="/crafted/accounts" title="Tópicos de avaliações" hasBullet={true}>
          <AsideMenuItem to="/review-settings/topics" title="Produtos" hasBullet={true} />
          <AsideMenuItem to="/store-reviews/settings/topics" title="Loja" hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItem to="/review-settings/coupons" title="Cupons de desconto" hasBullet={true} />{" "}
        <AsideMenuItem to="/notifications/store-cadence" title="Notificações" hasBullet={true} />
        <AsideMenuItemWithSub to="/review-settings/integrations/webhooks" title="Integrações" hasBullet={true}>
          <AsideMenuItem to="/review-settings/integrations/webhooks" title="Webhooks" hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Konfidency Quest
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/nps"
        title="NPS"
        fontIcon="bi-archive"
        icon="/media/icons/duotone/General/Smile.svg"
      >
        <AsideMenuItem
          to="/nps/overview"
          title="Visão geral"
          hasBullet={true}
        />
        <AsideMenuItem to="/nps/responses" title="Respostas" hasBullet={true} />
        <AsideMenuItem
          to="/nps/settings"
          title="Configurar pesquisa"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      {isAdmin && (
        <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Asgard
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/customers"
        title="Clientes"
        fontIcon="bi-archive"
        icon="/media/icons/duotone/General/Smile.svg"
      >
        <AsideMenuItem
          to="/customers/list"
          title="Gerenciar clientes"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/customers/new"
          title="Novo cliente"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/customers/import-review"
          title="Importar avaliações"
          hasBullet={true}
        />
        {/* <AsideMenuItem
          to="/nps/settings"
          title="Configurar pesquisa"
          hasBullet={true}
        /> */}
      </AsideMenuItemWithSub>
      </>
      )}
    </>
  );
}
